import React from 'react'
import { ThemeProvider as MaterialThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { useStore } from 'hooks/useStore'
import { theme } from './theme'

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  const [{ themeMode }] = useStore()
  const isDark = themeMode === 'dark'
  return (
    <MaterialThemeProvider theme={theme(isDark)}>
      <CssBaseline />
      {React.Children.only(props.children)}
    </MaterialThemeProvider>
  )
}

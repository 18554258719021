import { createTheme } from '@mui/material/styles'
import { sharedTheme } from 'data/sharedTheme'

export const theme = (isDark: boolean) => {
  const materialTheme = createTheme()
  return createTheme({
    palette: {
      mode: isDark ? 'dark' : 'light',
      primary: {
        main: sharedTheme.primary,
        dark: '#c34400',
        light: '#ffa548',
      },
      secondary: {
        main: '#212121',
        dark: '#000000',
        light: '#484848',
      },
      text: {
        secondary: 'rgba(153, 153, 159, 1)',
      },
    },
    typography: {
      fontFamily: ['Biennale', 'sans-serif'].join(','),
      h1: {
        fontWeight: 400,
        fontSize: 44,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 40,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 34,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 28,
        },
      },
      h2: {
        fontWeight: 700,
        fontSize: 36,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 32,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 27,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 24,
        },
      },
      h3: {
        fontWeight: 400,
        fontSize: 30,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 28,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 24,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 22,
        },
      },
      h4: {
        fontWeight: 400,
        fontSize: 26,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 24,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 22,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 20,
        },
      },
      h5: {
        fontWeight: 700,
        fontSize: 22,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 20,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 19,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 18,
        },
      },
      h6: {
        fontWeight: 700,
        fontSize: 18,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 17,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 16,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 15,
        },
      },
      subtitle1: {
        fontWeight: 400,
        fontSize: 20,
        color: '#fff',
        [materialTheme.breakpoints.down('lg')]: {
          fontSize: 19,
        },
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 18,
        },
        [materialTheme.breakpoints.down('sm')]: {
          fontSize: 17,
        },
      },
      subtitle2: {
        fontWeight: 400,
        fontSize: 18,
        color: '#fff',
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 17,
        },
      },
      body1: {
        fontWeight: 400,
        fontSize: 16,
        color: '#fff',
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 15,
        },
      },
      body2: {
        fontWeight: 400,
        fontSize: 14,
        color: '#fff',
        [materialTheme.breakpoints.down('md')]: {
          fontSize: 13,
        },
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            height: '100%',
          },
          '& #__next': {
            minHeight: '100vh',
            background: 'rgb(0,0,0,0)',
            backgroundImage:
              'linear-gradient(0deg, rgba(0, 0, 0, .25) 0%, rgba(32, 30, 30, .25)  100%)',
          },
          body: {
            backgroundColor: 'rgb(26,26,26)',
          },
          a: {
            textDecoration: 'none',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fieldset: {
              border: '2px solid white',
            },
          },
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px #666666 inset',
            },
          },
        },
      },
    },
  })
}

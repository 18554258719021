const songstarterProduction: configType = {
  firebaseConfig: {
    apiKey: 'AIzaSyCGCbPlbT_b3IFXP88bH5aosQlYpmjOZVc',
    authDomain: 'song-starter.firebaseapp.com',
    projectId: 'song-starter',
    storageBucket: 'song-starter.appspot.com',
    messagingSenderId: '924664322673',
    appId: '1:924664322673:web:69089507498f8af88919d0',
    measurementId: 'G-KKMVSMTVFL',
  },
  firebaseEmulator: false,
  environment: 'production',
  googleTagManagerID: 'GTM-5PNR4J8',
  paypalClientID:
    'AXT3R-sRnMLr2O_HvjSCXAsmO_a2Q9j1PuQCYjMUM7hdrI74TFkX6_hmKVj41sWtzDVHwrtl9DIl0gnj',
  backendURL: 'https://song-starter.uc.r.appspot.com/',
  stripePublicKey:
    'pk_live_51Lcp51L5Q0B9DVXljWNxR0Be65wQfwyJyd9fYE10Sg3H3nPPslxHsYRBmhRDTn7XN9pnxkZcGrZ0b4pFVVT9YTcK004FrtYCyj',
}

const songstarterStaging: configType = {
  firebaseConfig: {
    apiKey: 'AIzaSyDV1niL-BStcBhhwqtnwHrTGbty69XqvEk',
    authDomain: 'songstarter-staging.firebaseapp.com',
    projectId: 'songstarter-staging',
    storageBucket: 'songstarter-staging.appspot.com',
    messagingSenderId: '484420261910',
    appId: '1:484420261910:web:4d60f25a4acb35b617bd37',
    measurementId: 'a',
  },
  firebaseEmulator: false,
  environment: 'development',
  googleTagManagerID: '',
  paypalClientID:
    'AYPzUMAqkrlKex1fvNESf3KUKc4x8pd5E4aGxV_RsmxGrOxZ3G8DlWZevo_91EFGVmXkPrZUFZjWiVH0',
  backendURL:
    process.env.NEXT_PUBLIC_BACKEND_URL ||
    'https://songstarter-staging.uc.r.appspot.com/',
  stripePublicKey:
    'pk_test_51KxqdZDygc58MFqYOoxNEVMGBbzVPRBXxqSGyqE0GHXvEXiUjipyJdADMBPV8km6QRZPImVsRcCNg024qghOu38j00QB17OjID',
}

const songstarterDev: configType = {
  ...songstarterStaging,
  firebaseEmulator: true,
  environment: 'development',
  backendURL: process.env.NEXT_PUBLIC_BACKEND_URL || 'http://localhost:4001/',
}

export const config =
  process.env.NEXT_PUBLIC_PROJECT === 'song-starter'
    ? songstarterProduction
    : process.env.NEXT_PUBLIC_PROJECT === 'songstarter-staging'
    ? songstarterStaging
    : songstarterDev

export type configType = {
  firebaseConfig: {
    apiKey: string
    authDomain: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    measurementId: string
    appId: string
  }
  firebaseEmulator?: boolean
  environment: 'development' | 'production' | 'test'
  paypalClientID: string
  googleTagManagerID: string
  backendURL: string
  stripePublicKey: string
}

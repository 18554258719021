import { config } from 'configs/config'
declare global {
  interface Window {
    gtag: any
    dataLayer: any
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const googleAnalyticsPageview = url => {
  window?.gtag('config', config.firebaseConfig.measurementId, {
    page_path: url,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  window?.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  })
}

/** Google Tag Manager utils */
export const googleTagManagerPageview = url => {
  window?.dataLayer?.push({
    event: 'pageview',
    page: url,
  })
}

/** Google Tag Manager Datalayer push */
export const dataLayerPush = (eventName, values) => {
  window?.dataLayer?.push({
    event: eventName,
    ...values,
  })
}
